import { defineStore } from 'pinia';
import { ref } from 'vue';
import type { ParkingFormData, LoungeFormData } from '@white-label-types/search-box';

type SearchBoxFormData = ParkingFormData | LoungeFormData;
type Timestamp = Date | string | null;
type SearchBoxParams = { [key: string]: string };

export const useSearchBoxStore = defineStore('searchBox', {
  state: () => {
    const searchBoxFormData = ref<SearchBoxFormData>({
      entryDate: '',
      entryTime: null,
      exitDate: '',
      exitTime: null,
      terminal: undefined,
      date: '',
      discount: null,
    });
    const params = ref<SearchBoxParams>({});
    const formType = ref<string>('');
    const searchBoxTimestamp = ref<Timestamp>(null);

    function updateStoreData(payload: { formData: SearchBoxFormData, timestamp: Timestamp }) {
      searchBoxFormData.value = { ...payload.formData };
      searchBoxTimestamp.value = payload.timestamp;
    }

    function clearStoreData() {
      searchBoxFormData.value = {
        entryDate: '',
        entryTime: null,
        exitDate: '',
        exitTime: null,
        terminal: undefined,
        date: '',
        discount: null,
      };
      params.value = {};
      formType.value = '';
      searchBoxTimestamp.value = null;
    }

    function saveSearchBoxParams(payload: { id: string; data: object }) {
      if (payload.id && payload.data && params.value)
        params.value[payload.id] = JSON.stringify(payload.data);
    }

    function getSearchBoxParams(id: string) {
      return params.value && params.value[id] ? JSON.parse(params.value[id]) : {};
    }

    return {
      searchBoxFormData,
      formType,
      searchBoxTimestamp,
      updateStoreData,
      clearStoreData,
      saveSearchBoxParams,
      getSearchBoxParams,
    }
  },
  persist: true,
});
