import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _09d9a868 = () => interopDefault(import('../src/pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _2b551064 = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _aa65749a = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _513def56 = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _07837989 = () => interopDefault(import('../src/pages/receipt.vue' /* webpackChunkName: "pages/receipt" */))
const _2cc25867 = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _f11ab994 = () => interopDefault(import('../src/pages/search-booking/index.vue' /* webpackChunkName: "pages/search-booking/index" */))
const _35c17cb0 = () => interopDefault(import('../src/pages/select-extras/index.vue' /* webpackChunkName: "pages/select-extras/index" */))
const _20dbe41a = () => interopDefault(import('../src/pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _a5c10d3e = () => interopDefault(import('../src/pages/unsupported-browser.vue' /* webpackChunkName: "pages/unsupported-browser" */))
const _41b70dac = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _65b0f7ae = () => interopDefault(import('../src/pages/manage-booking/modify.vue' /* webpackChunkName: "pages/manage-booking/modify" */))
const _d6ac844e = () => interopDefault(import('../src/pages/manage-booking/receipt.vue' /* webpackChunkName: "pages/manage-booking/receipt" */))
const _5490d0a6 = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _41d38488 = () => interopDefault(import('../src/pages/select-extras/fasttrack/index.vue' /* webpackChunkName: "pages/select-extras/fasttrack/index" */))
const _55a61f92 = () => interopDefault(import('../src/pages/select-extras/lounges/index.vue' /* webpackChunkName: "pages/select-extras/lounges/index" */))
const _403ed2f0 = () => interopDefault(import('../src/pages/select-extras/product/index.vue' /* webpackChunkName: "pages/select-extras/product/index" */))
const _18416b92 = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _67422d63 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cookie-policy",
    component: _09d9a868,
    name: "cookie-policy"
  }, {
    path: "/maintenance",
    component: _2b551064,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _aa65749a,
    name: "manage-booking"
  }, {
    path: "/privacy-policy",
    component: _513def56,
    name: "privacy-policy"
  }, {
    path: "/receipt",
    component: _07837989,
    name: "receipt"
  }, {
    path: "/search",
    component: _2cc25867,
    name: "search"
  }, {
    path: "/search-booking",
    component: _f11ab994,
    name: "search-booking"
  }, {
    path: "/select-extras",
    component: _35c17cb0,
    name: "select-extras"
  }, {
    path: "/terms-and-conditions",
    component: _20dbe41a,
    name: "terms-and-conditions"
  }, {
    path: "/unsupported-browser",
    component: _a5c10d3e,
    name: "unsupported-browser"
  }, {
    path: "/manage-booking/cancel",
    component: _41b70dac,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/modify",
    component: _65b0f7ae,
    name: "manage-booking-modify"
  }, {
    path: "/manage-booking/receipt",
    component: _d6ac844e,
    name: "manage-booking-receipt"
  }, {
    path: "/manage-booking/update-details",
    component: _5490d0a6,
    name: "manage-booking-update-details"
  }, {
    path: "/select-extras/fasttrack",
    component: _41d38488,
    name: "select-extras-fasttrack"
  }, {
    path: "/select-extras/lounges",
    component: _55a61f92,
    name: "select-extras-lounges"
  }, {
    path: "/select-extras/product",
    component: _403ed2f0,
    name: "select-extras-product"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _18416b92,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _67422d63,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
